import React from 'react';
import { withRouter } from 'react-router-dom';
import store, { RootState, actions } from '../store/index';
import Header from '../components/Header/Header';
import { IonItemGroup, IonItem, IonLabel, IonInput, withIonLifeCycle, IonRadioGroup, IonRadio, IonDatetime, IonIcon, IonCheckbox, IonTextarea, IonItemDivider, IonSelect, IonSelectOption, IonCol, IonRow, IonGrid, IonContent, IonText, IonPage } from '@ionic/react';
import { connect } from 'react-redux';
import { validateForm } from '../helpers/validation';
import { calendar, add } from 'ionicons/icons';
import LimeAPI from '../classes/LimeAPI';
import { loadingController, toastController } from '@ionic/core';
import { QualityDocumentsInterface } from '../store/qualityDocuments/types';
import QualityDocumentsHeader from '../components/QualityDocuments/QualityDocumentsHeader';
import dayjs from 'dayjs';
import { pictureToBase64 } from '../helpers/picture';
import isBrowser from '../helpers/is-browser';
import { Camera, CameraResultType } from '@capacitor/camera';
import { currentUserIsAdmin } from '../helpers/roles';
import { APIUserInterface } from '../store/user/actions';


class PageEditQualityDocument extends React.Component<any, any> {
  abortController = new AbortController();
  supplierAbortController = new AbortController();
  floorAbortController = new AbortController();
  wallzone1AbortController = new AbortController();
  wallzone2AbortController = new AbortController();
  fileInputRef: any;
  floorRef: any;
  floorSystemRef: any;
  wallzone1Ref: any;
  wallzone1SystemRef: any;
  wallzone2Ref: any;
  wallzone2SystemRef: any;

  constructor(props: any) {
    super(props);

    this.selectFiles = this.selectFiles.bind(this);
    this.openFileInput = this.openFileInput.bind(this);
    this.fileInputRef = React.createRef();
    this.floorRef = React.createRef();
    this.floorSystemRef = React.createRef();
    this.wallzone1Ref = React.createRef();
    this.wallzone1SystemRef = React.createRef();
    this.wallzone2Ref = React.createRef();
    this.wallzone2SystemRef = React.createRef();

    this.state = {
      supplier: {},
      suppliersystemfloor: {},
      suppliersystemwallzone1: {},
      suppliersystemwallzone2: {},
      isValid: false,
      formData: {
        _id: {
          required: true,
          value: 0,
        },
        authorizationno: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        clientname: {
          required: true,
          value: '',
        },
        companyname: {
          required: true,
          value: '',
        },
        copytoauthorizedcompany: {
          required: false,
          value: false,
        },
        floorfoundationok: {
          required: true,
          value: undefined,
        },
        floorslope: {
          required: false,
          value: false,
        },
        companysupplierfloor: {
          required: true,
          value: '',
        },
        suppliersystemfloor: {
          required: true,
          value: '',
        },
        newproduction: {
          required: true,
          value: false,
          relation: ['renovation'],
        },
        objectname: {
          required: true,
          value: '',
        },
        otherinformation: {
          required: true,
          value: '',
        },
        otherleadthrough: {
          required: true,
          value: undefined,
        },
        personsupervisor: {
          required: true,
          value: 0,
        },
        persontiler: {
          required: true,
          value: 0,
        },
        picture: {
          required: false,
          value: [],
        },
        project: {
          required: true,
          value: '',
        },
        registrationno: {
          required: true,
          value: '',
        },
        renovation: {
          required: true,
          value: false,
          relation: ['newproduction'],
        },
        responsibilityinsurance: {
          required: true,
          value: '',
        },
        sealingworkok: {
          required: false,
          value: undefined,
        },
        supplementtoclient: {
          required: false,
          value: false,
        },
        wallfoundationok: {
          required: true,
          value: undefined,
        },
        companywallzone1supplier: {
          required: true,
          value: '',
        },
        suppliersystemwallzone1: {
          required: true,
          value: '',
        },
        companywallzone2supplier: {
          required: true,
          value: '',
        },
        suppliersystemwallzone2: {
          required: true,
          value: '',
        },
        wellbyothercontractor: {
          required: false,
          value: false,
        },
        wellfixedmount: {
          required: false,
          value: false,
        },
        welltypeapproved: {
          required: false,
          value: false,
        },
        wetroomamount: {
          required: true,
          value: '',
        },
        workfrom: {
          required: true,
          value: '',
        },
        workto: {
          required: true,
          value: '',
        },
      },
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.saveQualityDocument = this.saveQualityDocument.bind(this);
  }

  ionViewWillEnter() {
    const {
      currentQualityDocument,
      currentProject,
      user,
    } = this.props;

    this.getSuppliers();

    this.setState({
      supplier: {},
      isValid: false,
      formData: {
        _id: {
          required: true,
          value: currentQualityDocument._id,
        },
        authorizationno: {
          required: true,
          value: user.company.qualificationNo,
        },
        city: {
          required: true,
          value: currentQualityDocument.city,
        },
        clientname: {
          required: true,
          value: currentQualityDocument.clientname,
        },
        company: {
          required: true,
          value: user.company.id,
        },
        companyname: {
          required: true,
          value: user.company.name,
        },
        copytoauthorizedcompany: {
          required: false,
          value: currentQualityDocument.copytoauthorizedcompany,
        },
        floorfoundationok: {
          required: true,
          value: currentQualityDocument.floorfoundationok,
        },
        floorslope: {
          required: false,
          value: currentQualityDocument.floorslope,
        },
        companysupplierfloor: {
          required: true,
          value: currentQualityDocument.companysupplierfloor._id,
        },
        suppliersystemfloor: {
          required: true,
          value: currentQualityDocument.suppliersystemfloor._id,
        },
        newproduction: {
          required: true,
          value: currentQualityDocument.newproduction,
          relation: ['renovation'],
        },
        objectname: {
          required: true,
          value: currentQualityDocument.objectname,
        },
        otherinformation: {
          required: ! (
            this.state.formData.floorfoundationok.value === true &&
            this.state.formData.wallfoundationok.value === true &&
            this.state.formData.otherleadthrough.value === false),
          value: currentQualityDocument.otherinformation,
        },
        otherleadthrough: {
          required: true,
          value: currentQualityDocument.otherleadthrough,
        },
        personsupervisor: {
          required: true,
          value: currentQualityDocument.personsupervisor,
        },
        persontiler: {
          required: true,
          value: currentQualityDocument.persontiler,
        },
        picture: {
          required: false,
          value: currentQualityDocument.picture.map((picture: any) => `data:image/${picture.extension};base64,${picture.data}`),
        },
        project: {
          required: true,
          value: currentProject.id,
        },
        registrationno: {
          required: true,
          value: user.company.orgNo,
        },
        renovation: {
          required: true,
          value: currentQualityDocument.renovation,
          relation: ['newproduction'],
        },
        responsibilityinsurance: {
          required: true,
          value: currentQualityDocument.responsibilityinsurance,
        },
        sealingworkok: {
          required: false,
          value: currentQualityDocument.sealingworkok,
        },
        supplementtoclient: {
          required: false,
          value: currentQualityDocument.supplementtoclient,
        },
        wallfoundationok: {
          required: true,
          value: currentQualityDocument.wallfoundationok,
        },
        companywallzone1supplier: {
          required: true,
          value: currentQualityDocument.companywallzone1supplier._id,
        },
        suppliersystemwallzone1: {
          required: true,
          value: currentQualityDocument.suppliersystemwallzone1._id,
        },
        companywallzone2supplier: {
          required: true,
          value: currentQualityDocument.companywallzone2supplier._id,
        },
        suppliersystemwallzone2: {
          required: true,
          value: currentQualityDocument.suppliersystemwallzone2._id,
        },
        wellbyothercontractor: {
          required: false,
          value: currentQualityDocument.wellbyothercontractor,
        },
        wellfixedmount: {
          required: false,
          value: currentQualityDocument.wellfixedmount,
        },
        welltypeapproved: {
          required: false,
          value: currentQualityDocument.welltypeapproved,
        },
        wetroomamount: {
          required: true,
          value: currentQualityDocument.wetroomamount,
        },
        workfrom: {
          required: true,
          value: currentQualityDocument.workfrom,
        },
        workto: {
          required: true,
          value: currentQualityDocument.workto,
        },
        wetworkteamlead: {
          required: false,
          value: '',
        },
        tilername: {
          required: false,
          value: '',
        },
      },
    });
  }

  onInputChange(event: any) {
    this.saveState(event);

    const { name } = event.target;
    const supplierInputs = ['companysupplierfloor', 'companywallzone1supplier', 'companywallzone2supplier'];

    if (supplierInputs.includes(name)) {
      this.handleSupplierChange(event);
    }
  }

  handleSupplierChange(event: any) {
    const supplierId = event.target.value;
    const target = event.target.name.replace('supplier', '').replace('company', 'suppliersystem');
    const isFloorSystem = event.target.name.indexOf('floor') > -1;

    LimeAPI.get(`suppliers/${supplierId}/system`, this.supplierAbortController.signal)
      .then(response => this.setState({ [target]: response.data[ isFloorSystem ? 'floorsuppliers' : 'wallsuppliers'] }))
      .catch(() => false);
  }

  saveState(event: any) {
    const target = event.target;
    const value = event.target.localName === 'ion-checkbox' ? target.checked : target.value;
    const name = target.name;

    const { formData }: any = this.state;
    const { currentProject }: any = this.props;

    const relations: any = {};

    const inputName = target.getAttribute('name');
    const personFieldsLookup: any = {
      personsupervisor: 'wetworkteamlead',
      persontiler: 'tilername',
    };

    if (formData[name] && formData[name].relation) {
      for (const key in formData[name].relation) {
        if (formData[name].relation.hasOwnProperty(key)) {
          const relationName = formData[name].relation[key];
          relations[relationName] = {
            ...formData[relationName],
            required: false,
            value: false,
          };
        }
      }
    }

    // refactor this
    if (inputName === 'persontiler') {
      const personField = personFieldsLookup[inputName];

      const personMatch = currentProject.persons.tilers.find((person: any) => person.personId === target.value);

      if (personMatch) {
        formData[personField].value = personMatch.name;
      }
    }

    // refactor this
    if (inputName === 'personsupervisor') {
      const personField = personFieldsLookup[inputName];

      const personMatch = (currentProject.persons.supervisors).find((person: any) => person.personId === target.value);

      if (personMatch) {
        formData[personField].value = personMatch.name;
      }
    }

    this.setState(
      {
        formData: {
          ...this.state.formData,
          [name]: {
            value,
            isTouched: true,
            relation: formData[name] && formData[name].relation !== undefined ? formData[name].relation : undefined,
            required: formData[name] && formData[name].required !== undefined ? formData[name].required : undefined,
          },
          ...relations,
        },
      }, () => {
        const notesConditionals =
          this.state.formData.floorfoundationok.value === true &&
          this.state.formData.wallfoundationok.value === true &&
          this.state.formData.otherleadthrough.value === false;

        this.setState({
          formData: {
            ...this.state.formData,
            otherinformation: {
              ...this.state.formData.otherinformation,
              required: ! notesConditionals,
            },
          },
        }, () => this.setState({ isValid: validateForm(this.state) }));

        this.setState({ isValid: validateForm(this.state) });
      }
    );
  }

  saveQualityDocument() {
    const data: any = {};
    const { formData } = this.state;

    loadingController.create({
      spinner: 'crescent',
      message: 'Sparar kvalitetsdokument...',
    }).then(controller => controller.present()).catch();

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        data[key] = formData[key].value;
      }
    }

    LimeAPI.put(`qualitydocuments/${this.props.currentQualityDocument._id}`, data, this.abortController.signal)
      .then(response => {
        loadingController.dismiss().catch();

        if (! response.success) {
          toastController.create({
            message: 'Kvalitetsdokumentet kunde inte sparas, försök igen.',
            color: 'dark',
            buttons: ['Ok'],
          }).then(toast => toast.present()).catch();

          return;
        }

        this.props.setCurrentQualityDocument(response);

        toastController.create({
          message: `Kvalitetsdokumentet "${response.data.objectname}" har uppdaterats.`,
          duration: 3000,
          color: 'success',
        }).then(toast => toast.present()).catch();

        return this.props.history.push(`/projects/${this.props.currentQualityDocument.project}/`);
      })
      .catch(() => false);
  }

  async selectFiles(files: any) {
    if (files[0] === undefined) {
      return;
    }

    Promise.resolve(pictureToBase64(files[0]))
      .then(async (base64File: any) => this.addPicture(base64File))
      .then(() => this.setState({ inputValue: '' }))
      .catch();
  }

  openFileInput() {
    isBrowser() ? this.fileInputRef.current.click() : this.getPicture();
  }

  removePicture(index: number) {
    const pictures: any = this.state.formData.picture.value;
    const filteredPictures = pictures.filter((picture: any, pictureIndex: number) => pictureIndex !== index);

    this.savePictureState(filteredPictures);
  }

  savePictureState(pictures: any) {
    this.setState({
      formData: {
        ...this.state.formData,
        picture: {
          value: pictures,
          required: this.state.formData.picture.required,
        },
      },
    }, () => this.setState({ isValid: validateForm(this.state) }));
  }

  addPicture(picture: any) {
    const pictures = [
      ...this.state.formData.picture.value,
      picture,
    ];
    this.savePictureState(pictures);
  }

  async getPicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
    });

    this.addPicture(`data:image/${image.format};base64,${image.base64String}`);
  }

  getSuppliers() {
    LimeAPI.get('suppliers', this.supplierAbortController.signal)
      .then(response => this.setState({ supplier: response.data }))
      .then(() => {
        this.floorRef.current.placeholder = '';
        this.wallzone1Ref.current.placeholder = '';
        this.wallzone2Ref.current.placeholder = '';
      })
      .then(() => this.getSupplierSystems())
      .catch(() => false);
  }

  async getSupplierSystems() {
    const { formData } = this.state;

    LimeAPI.get(`suppliers/${formData.companysupplierfloor.value}/system`, this.floorAbortController.signal)
      .then((response: any) => this.setState({ suppliersystemfloor: response.data.floorsuppliers }))
      .then(() => this.floorSystemRef.current.placeholder = '')
      .catch(() => false);

    LimeAPI.get(`suppliers/${formData.companywallzone1supplier.value}/system`, this.wallzone1AbortController.signal)
      .then((response: any) => this.setState({ suppliersystemwallzone1: response.data.wallsuppliers }))
      .then(() => this.wallzone1SystemRef.current.placeholder = '')
      .catch(() => false);

    LimeAPI.get(`suppliers/${formData.companywallzone2supplier.value}/system`, this.wallzone2AbortController.signal)
      .then((response: any) => this.setState({ suppliersystemwallzone2: response.data.wallsuppliers }))
      .then(() => this.wallzone2SystemRef.current.placeholder = '')
      .catch(() => false);
  }

  render() {
    if (this.state && this.state.length === 0) {
      return <div />;
    }

    const { currentProject, currentQualityDocument } = this.props;
    const { user } = store.getState();
    const { formData } = this.state;
    let { supervisors, tilers } = currentProject.persons;
    const numbers = new Array(99).fill(1);
    supervisors = (supervisors || []);
    tilers = (tilers || []);

    const fileInput = isBrowser() ?
      <input
        type="file"
        accept=".png,.jpg,.jpeg"
        ref={this.fileInputRef}
        style={{ display: 'none' }}
        onChange={(e: any) => this.selectFiles(e.target.files)}
        value={this.state.inputValue}
        /> : '';

    const tilersMarkup = [];

    const combos = (supervisors || []).filter((supervisor: any) => supervisor.position.key === 'combo');

    const uniqueIds: any[] = [];

    const tilersAndCombo = ([...tilers, ...combos] || []).filter((user: any) => {
      if (uniqueIds.includes(user.personId)) {
        return false;
      }

      uniqueIds.push(user.personId);

      return true;
    });

    if (currentUserIsAdmin()) {
      (tilersAndCombo || []).forEach((tiler: any) => {
        tilersMarkup.push(<IonSelectOption key={`tiler-${parseInt(tiler._id, 10)}`} value={parseInt(tiler.personId, 10)}>{tiler.name}</IonSelectOption>);
      });
    } else {
      tilersMarkup.push(<IonSelectOption key={user.id} value={user.id} selected={true}>{user.name}</IonSelectOption>);
    }

    let documentation;

    if (currentUserIsAdmin()) {
      documentation =
        <IonItemGroup className="form-section">
        <IonItemDivider sticky={true} mode="ios">Dokumentation</IonItemDivider>

        <div className="form-group form-group-simple form-group-cb">
          <IonItem lines="none" className="ion-no-padding">
            <IonCheckbox
              name="supplementtoclient"
              onIonChange={this.onInputChange}
              checked={formData.supplementtoclient.value}
            />
            <IonLabel>Kvalitetsdokument lämnad till beställare</IonLabel>
          </IonItem>

          <IonItem lines="none" className="ion-no-padding">
            <IonCheckbox
              name="copytoauthorizedcompany"
              onIonChange={this.onInputChange}
              checked={formData.copytoauthorizedcompany.value}
            />
            <IonLabel>Kopia sparad hos behörigt företag</IonLabel>
          </IonItem>
        </div>
      </IonItemGroup>;
    }

    const controlText = currentQualityDocument.version <= 3 ?
    'Egenkontrollen innefattar följande kontrollpunkter med hänvisning till kapitel 6:533 i Boverkets byggregler, BFS 2011:6 med ändringar t.o.m. BFS 2015:3, BBR'
    :
    'Egenkontrollen innefattar följande kontrollpunkter med hänvisning till kapitel 6:533 i Boverkets byggregler, BFS 2011:6 med ändringar t.o.m. BFS 2020:4, BBR';

    return(
    <IonPage>
      <Header
        componentLeft="cancel"
        title={currentQualityDocument.objectname}
        componentRight="saveQualityDocumentButton"
        componentRightHandler={this.saveQualityDocument}
        componentSettings={this.state}
        />
        <IonContent>
          <QualityDocumentsHeader hideStatus={true}/>

          <IonGrid className="ion-padding-top ion-padding-bottom">
            <IonRow className="ion-justify-content-center">
              <IonCol
                sizeSm="10"
                sizeMd="8"
                sizeLg="6"
                >

                <IonItemGroup className="form-section">
                  <IonItem className="form-group ion-no-padding" color="white" lines="none">
                    <IonLabel position="stacked" className="m-0">
                      Ansvarsförsäkring i försäkringsbolag
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="responsibilityinsurance"
                      type="text"
                      placeholder="Ex. Lorem ipsum dolor"
                      onIonChange={this.onInputChange}
                      value={formData.responsibilityinsurance.value}
                      autocapitalize="on"
                      />
                  </IonItem>

                  <IonItem className="form-group ion-no-padding" color="white" lines="none">
                    <IonLabel position="stacked" className="m-0">
                      Objekt
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="objectname"
                      type="text"
                      placeholder="Ex. Lorem ipsum dolor"
                      onIonChange={this.onInputChange}
                      value={formData.objectname.value}
                      autocapitalize="on"
                      />
                  </IonItem>

                  <IonItem className="form-group ion-no-padding" color="white" lines="none">
                    <IonLabel position="stacked" className="m-0">
                      Beställare
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="clientname"
                      type="text"
                      placeholder="Ex. Lorem ipsum dolor"
                      onIonChange={this.onInputChange}
                      value={formData.clientname.value}
                      autocapitalize="on"
                      />
                  </IonItem>

                  <div className="form-group-multiple">
                    <IonRadioGroup>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="newproduction"
                          onIonSelect={this.onInputChange}
                          checked={formData.newproduction.value}
                          value={true}
                        />
                        <IonLabel>Avser nyproduktion</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                    <IonRadioGroup>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="renovation"
                          onIonSelect={this.onInputChange}
                          checked={formData.renovation.value}
                          value={true}
                        />
                        <IonLabel>Avser renovering</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  </div>

                  <h3>Arbetena utförda under perioden<span style={{ color: 'red', fontSize: '24px' }}>*</span></h3>

                  <IonItem className="form-group form-group-datetime ion-no-padding d-flex ion-align-items-center" color="white" lines="none">
                    <IonIcon icon={calendar} color="primary" />
                    <IonDatetime
                      displayFormat="YYYY-MM-DD"
                      placeholder="Från"
                      onIonChange={this.onInputChange}
                      name="workfrom"
                      value={formData.workfrom.value}
                      min={store.getState().user.permissionDate}
                      max={dayjs().format('YYYY-MM-DD')}
                    />
                    <span className="datetime-divider"></span>
                    <IonDatetime
                      displayFormat="YYYY-MM-DD"
                      placeholder="Till"
                      onIonChange={this.onInputChange}
                      name="workto"
                      value={formData.workto.value}
                      min={store.getState().user.permissionDate}
                      max={dayjs().format('YYYY-MM-DD')} />
                  </IonItem>
                </IonItemGroup>

                <IonItemGroup className="form-section">
                  <IonItemDivider sticky={true} mode="ios">Tätskiktssystem</IonItemDivider>

                  <h2 className="ion-margin-bottom">Av Byggkeramikrådet godkända tätskiktssystem enligt BBV</h2>

                  <div className="form-group-multiple has-select">
                    <IonItem className="form-group-item" color="white" lines="none">
                      <IonLabel position="stacked">
                        Golv tillverkare/leverantör
                        <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      </IonLabel>
                      <IonSelect
                        name="companysupplierfloor"
                        className="w-100"
                        placeholder="Hämtar data..."
                        onIonChange={this.onInputChange}
                        ref={this.floorRef}
                        value={formData.companysupplierfloor.value.toString()}
                        >
                        {(Object.keys(this.state.supplier) || []).map((supplierKey: any) => {
                          const supplier = this.state.supplier[ supplierKey ];
                          return <IonSelectOption
                            key={`floor-${supplier._id}`}
                            value={supplier._id.toString()}
                            selected={supplier._id === formData.companysupplierfloor.value }
                            >{supplier.suppliername}</IonSelectOption>;
                        })}
                      </IonSelect>
                    </IonItem>

                    <IonItem className="form-group-item" color="white" lines="none">
                      <IonLabel position="stacked">
                        Golv systembenämning
                        <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      </IonLabel>
                      <IonSelect
                        name="suppliersystemfloor"
                        className="w-100"
                        placeholder="Hämtar data..."
                        onIonChange={this.onInputChange}
                        ref={this.floorSystemRef}
                        value={formData.suppliersystemfloor.value.toString()}
                        >
                        {(Object.keys(this.state.suppliersystemfloor) || []).map((systemKey: any) => {
                          const system = this.state.suppliersystemfloor[ systemKey ];
                          return <IonSelectOption key={`suppliersystemfloor-${system._id}`} value={system._id.toString()}>{system.name}</IonSelectOption>;
                        })}
                      </IonSelect>
                    </IonItem>
                  </div>

                  <div className="form-group-multiple has-select">
                    <IonItem className="form-group-item" color="white" lines="none">
                      <IonLabel position="stacked">
                        Vägg våtzon 1 tillverkare/leverantör
                        <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      </IonLabel>
                      <IonSelect
                        name="companywallzone1supplier"
                        className="w-100"
                        placeholder="Hämtar data..."
                        onIonChange={this.onInputChange}
                        ref={this.wallzone1Ref}
                        value={formData.companywallzone1supplier.value.toString()}
                        >
                        {(Object.keys(this.state.supplier) || []).map((supplierKey: any) => {
                          const supplier = this.state.supplier[ supplierKey ];
                          return <IonSelectOption key={`wall-1-${supplier._id}`} value={supplier._id.toString()}>{supplier.suppliername}</IonSelectOption>;
                        })}
                      </IonSelect>
                    </IonItem>

                    <IonItem className="form-group-item" color="white" lines="none">
                      <IonLabel position="stacked">
                        Vägg våtzon 1 systembenämning
                        <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      </IonLabel>
                      <IonSelect
                        name="suppliersystemwallzone1"
                        className="w-100"
                        placeholder="Hämtar data..."
                        onIonChange={this.onInputChange}
                        ref={this.wallzone1SystemRef}
                        value={formData.suppliersystemwallzone1.value.toString()}
                        >
                        {(Object.keys(this.state.suppliersystemwallzone1) || []).map((systemKey: any) => {
                          const system = this.state.suppliersystemwallzone1[ systemKey ];
                          return <IonSelectOption key={`suppliersystemwallzone1-${system._id}`} value={system._id.toString()}>{system.name}</IonSelectOption>;
                        })}
                      </IonSelect>
                    </IonItem>
                  </div>

                  <div className="form-group-multiple has-select">
                    <IonItem className="form-group-item" color="white" lines="none">
                      <IonLabel position="stacked">
                        Vägg våtzon 2 tillverkare/leverantör
                        <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      </IonLabel>
                      <IonSelect
                        name="companywallzone2supplier"
                        className="w-100"
                        placeholder="Hämtar data..."
                        onIonChange={this.onInputChange}
                        ref={this.wallzone2Ref}
                        value={formData.companywallzone2supplier.value.toString()}
                        >
                        {(Object.keys(this.state.supplier) || []).map((supplierKey: any) => {
                          const supplier = this.state.supplier[ supplierKey ];
                          return <IonSelectOption key={`wall-2-${supplier._id}`} value={supplier._id.toString()}>{supplier.suppliername}</IonSelectOption>;
                        })}
                      </IonSelect>
                    </IonItem>

                    <IonItem className="form-group-item" color="white" lines="none">
                      <IonLabel position="stacked">
                        Vägg våtzon 2 systembenämning
                        <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      </IonLabel>
                      <IonSelect
                        name="suppliersystemwallzone2"
                        className="w-100"
                        placeholder="Hämtar data..."
                        onIonChange={this.onInputChange}
                        ref={this.wallzone2SystemRef}
                        value={formData.suppliersystemwallzone2.value.toString()}
                        >
                        {(Object.keys(this.state.suppliersystemwallzone2) || []).map((systemKey: any) => {
                          const system = this.state.suppliersystemwallzone2[ systemKey ];
                          return <IonSelectOption key={`suppliersystemwallzone2-${system._id}`} value={system._id.toString()}>{system.name}</IonSelectOption>;
                        })}
                      </IonSelect>
                    </IonItem>
                  </div>
                </IonItemGroup>

                <IonItemGroup className="form-section">
                  <IonItemDivider sticky={true} mode="ios">Egenkontroll</IonItemDivider>

                  <h2 className="ion-margin-bottom">{controlText}</h2>

                  <p>
                    Underlagen uppfyller branschreglernas krav (Golv)
                    <span style={{ color: 'red', fontSize: '24px' }}>*</span>
                  </p>
                  <div className="form-group-multiple">
                    <IonRadioGroup>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="floorfoundationok"
                          value={true}
                          onIonSelect={this.onInputChange}
                          checked={formData.floorfoundationok.value}
                        />
                        <IonLabel>Ja</IonLabel>
                      </IonItem>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="floorfoundationok"
                          value={false}
                          onIonSelect={this.onInputChange}
                          checked={formData.floorfoundationok.value === false}
                        />
                        <IonLabel>Nej</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  </div>
                  <p className="form-description font-size-xs">Om <strong>"nej"</strong> förtydliga nedan under <strong>övriga upplysningar</strong>.</p>

                  <p>
                    Underlagen uppfyller branschreglernas krav (Vägg)
                    <span style={{ color: 'red', fontSize: '24px' }}>*</span>
                  </p>
                  <div className="form-group-multiple">
                    <IonRadioGroup>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="wallfoundationok"
                          value={true}
                          onClick={this.onInputChange}
                          checked={formData.wallfoundationok.value}
                        />
                        <IonLabel>Ja</IonLabel>
                      </IonItem>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="wallfoundationok"
                          value={false}
                          onClick={this.onInputChange}
                          checked={formData.wallfoundationok.value === false}
                        />
                        <IonLabel>Nej</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  </div>
                  <p className="form-description font-size-xs">Om <strong>"nej"</strong> förtydliga nedan under <strong>övriga upplysningar</strong>.</p>

                  <p>
                    Finns andra rörgenomföringar genom golvets tätskikt än avlopp
                    <span style={{ color: 'red', fontSize: '24px' }}>*</span>
                  </p>
                  <div className="form-group-multiple">
                    <IonRadioGroup>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="otherleadthrough"
                          value={true}
                          onIonSelect={this.onInputChange}
                          checked={formData.otherleadthrough.value}
                        />
                        <IonLabel>Ja</IonLabel>
                      </IonItem>
                      <IonItem lines="none" color="white">
                        <IonRadio
                          name="otherleadthrough"
                          value={false}
                          onIonSelect={this.onInputChange}
                          checked={formData.otherleadthrough.value === false}
                        />
                        <IonLabel>Nej</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  </div>
                  <p className="form-description font-size-xs">Om <strong>"ja"</strong> förtydliga nedan under <strong>övriga upplysningar</strong>.</p>

                  <p>
                    Övriga upplysningar/eventuella avvikelser från Byggkeramikrådets Branschregler för Våtrum (BBV)
                    {formData.otherinformation.required && formData.otherinformation.value === '' ? <span style={{ color: 'red', fontSize: '24px' }}>*</span> : ''}
                  </p>
                  {formData.otherinformation.required && formData.otherinformation.value === '' ? <IonText color="danger"><p>Obligatoriskt att fylla i "Övriga upplysningar"</p></IonText> : ''}
                  <div className="form-group">
                    <IonTextarea
                      name="otherinformation"
                      placeholder="Skriv övriga upplysningar/avvikelser"
                      value={formData.otherinformation.value}
                      onIonChange={this.onInputChange}
                      autocapitalize="on"
                      maxlength={360}
                    />
                  </div>

                  <div className="form-group form-group-simple form-group-cb">
                    <IonItem lines="none" className="ion-no-padding">
                      <IonCheckbox
                        name="floorslope"
                        onIonChange={this.onInputChange}
                        checked={formData.floorslope.value}
                      />
                      <IonLabel>Lutning på golv mot golvavlopp uppfyller branschreglernas krav (1:50–1:150/1:100–1:200) innan tätskikt applicerats.</IonLabel>
                    </IonItem>

                    <IonItem lines="none" className="ion-no-padding">
                      <IonCheckbox
                        name="welltypeapproved"
                        onIonChange={this.onInputChange}
                        checked={formData.welltypeapproved.value}
                      />
                      <IonLabel>Har ny typgodkänd golvbrunn installerats eller är befintlig golvbrunn typgodkänd och intakt?</IonLabel>
                    </IonItem>

                    <IonItem lines="none" className="ion-no-padding">

                      <IonCheckbox
                        name="wellbyothercontractor"
                        onIonChange={this.onInputChange}
                        checked={formData.wellbyothercontractor.value}
                      />
                      <IonLabel>Ny golvbrunn monterad av annan entreprenör</IonLabel>
                    </IonItem>

                    <IonItem lines="none" className="ion-no-padding">
                    <IonCheckbox
                        name="wellfixedmount"
                        onIonChange={this.onInputChange}
                        checked={formData.wellfixedmount.value}
                      />
                      <IonLabel>Golvbrunnen är fast monterad, korrekt placerad och i nivå med tätskiktet</IonLabel>
                    </IonItem>

                    <IonItem lines="none" className="ion-no-padding">
                      <IonCheckbox
                        name="sealingworkok"
                        onIonChange={this.onInputChange}
                        checked={formData.sealingworkok.value}
                      />
                      <IonLabel>Tätskiktsarbetet är utfört enligt aktuell monteringsanvisning</IonLabel>
                    </IonItem>
                  </div>

                  <p>Ladda upp bilder</p>
                  <div className="form-group form-group-simple form-group-images d-flex">
                    {(formData.picture.value || []).map((picture: any, index: number) =>
                      <div className="image-col" key={index}>
                        <IonItem lines="none" color="white">
                          <img src={picture} alt="" />
                        </IonItem>
                        <span className="icon-remove" onClick={() => this.removePicture(index) }></span>
                      </div>
                    )}

                    {fileInput}

                    <div className="image-col">
                      <IonItem lines="none" color="white" onClick={this.openFileInput}>
                        <IonIcon icon={add} className="icon-add" color="primary" />
                      </IonItem>
                    </div>
                  </div>
                </IonItemGroup>

                <IonItemGroup className="form-section">
                  <IonItemDivider sticky={true} mode="ios">Behörig personal</IonItemDivider>

                  <div className="form-group has-select">
                    <IonLabel position="stacked">
                      Välj plattsättare som applicerat tätskiktet
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </IonLabel>
                    <IonSelect
                      name="persontiler"
                      cancelText="Avbryt"
                      okText="Lägg till"
                      placeholder="Lägg till plattsättare"
                      onIonChange={this.onInputChange}
                      value={formData.persontiler.value._id ? formData.persontiler.value._id : formData.persontiler.value}
                      >
                      {[tilersMarkup]}
                    </IonSelect>
                  </div>

                  <IonItem className="form-group ion-no-padding has-select" color="white" lines="none">
                    <IonLabel position="stacked">
                      Arbetsledare
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </IonLabel>
                    <IonSelect
                      name="personsupervisor"
                      className="w-100"
                      placeholder="Välj ett alternativ"
                      onIonChange={this.onInputChange}
                      value={formData.personsupervisor.value._id ? formData.personsupervisor.value._id : formData.personsupervisor.value}
                      >
                      {(supervisors || []).map((supervisor: any) => <IonSelectOption key={supervisor._id} value={supervisor.personId}>{supervisor.name}</IonSelectOption>)};
                    </IonSelect>
                  </IonItem>

                  <IonItem className="form-group ion-no-padding" color="white" lines="none">
                    <IonLabel position="stacked" className="m-0">
                      Ort
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="city"
                      onIonChange={this.onInputChange}
                      placeholder="Ex. Lorem ipsum dolor"
                      type="text"
                      value={formData.city.value}
                      autocapitalize="on"
                    />
                  </IonItem>

                  <IonItem className="form-group-item" color="white" lines="none">
                    <IonLabel position="stacked">
                      Dokumentet avser antal våtrum
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </IonLabel>
                    <IonSelect
                      name="wetroomamount"
                      className="w-100"
                      placeholder="Välj antal"
                      onIonChange={this.onInputChange}
                      value={formData.wetroomamount.value}
                      >
                      {Object.keys(numbers).map((num: string, index: number) => <IonSelectOption key={index + 1} value={`${(index + 1)}`}>{index + 1}</IonSelectOption>)}
                    </IonSelect>
                  </IonItem>
                </IonItemGroup>

                { documentation }
              </IonCol>
            </IonRow>
          </IonGrid>

        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    currentProject: state.currentProject,
    currentQualityDocument: state.currentQualityDocument,
  };
};

const mapDispatchToProps = {
  setCurrentQualityDocument: (document: QualityDocumentsInterface) => actions.qualityDocuments.updateQualityDocument(document),
  setCompanyDefaultStates: (user: APIUserInterface) => actions.user.setCompanyDefaultStates(user),
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(PageEditQualityDocument)));

// const PageEditQualityDocumentWithLifeCycle = withIonLifeCycle(PageEditQualityDocument);
// const ConnectedPageEditQualityDocument = connect(mapStateToProps, mapDispatchToProps)(PageEditQualityDocumentWithLifeCycle);
// const PageEditQualityDocumentWithRouter = withRouter(ConnectedPageEditQualityDocument);

// export default PageEditQualityDocumentWithRouter;
