import React from "react";
import {
  IonItemGroup,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonRadioGroup,
  IonRadio,
  IonIcon,
  IonCheckbox,
  IonTextarea,
  IonText,
} from "@ionic/react";
import { add } from "ionicons/icons";
import isBrowser from "../../helpers/is-browser";
import { pictureToBase64 } from "../../helpers/picture";
import { Camera, CameraResultType } from "@capacitor/camera";

class QualityDocumentSectionThree extends React.Component<any, any> {
  fileInputRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      inputValue: "",
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.selectFiles = this.selectFiles.bind(this);
    this.openFileInput = this.openFileInput.bind(this);

    this.fileInputRef = React.createRef();
  }

  onInputChange(event: any) {
    this.props.inputChangeCallback(event);
  }

  async selectFiles(files: any) {
    if (files[0] === undefined) {
      return;
    }

    Promise.resolve(pictureToBase64(files[0]))
      .then(async (base64File: any) => this.props.addPicture(base64File))
      .then(() => this.setState({ inputValue: "" }))
      .catch();
  }

  openFileInput() {
    isBrowser() ? this.fileInputRef.current.click() : this.getPicture();
  }

  async getPicture() {
    const image = await Camera.getPhoto({
      quality: 70,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      width: 1200,
    });

    this.props.addPicture(
      `data:image/${image.format};base64,${image.base64String}`
    );
  }

  render() {
    const { formData } = this.props;

    const fileInput = isBrowser() ? (
      <input
        type="file"
        accept=".png,.jpg,.jpeg"
        ref={this.fileInputRef}
        style={{ display: "none" }}
        onChange={(e: any) => this.selectFiles(e.target.files)}
        value={this.state.inputValue}
      />
    ) : (
      ""
    );

    return (
      <IonItemGroup className="form-section">
        <IonItemDivider sticky={true} mode="ios">
          Egenkontroll
        </IonItemDivider>

        <h2 className="ion-margin-bottom">
        Egenkontrollen innefattar följande kontrollpunkter med hänvisning till kapitel 6:533 i Boverkets byggregler, BFS 2011:6 med ändringar t.o.m. BFS 2020:4, BBR
        </h2>

        <p>
          Underlagen uppfyller branschreglernas krav (Golv)
          <span style={{ color: "red", fontSize: "24px" }}>*</span>
        </p>
        <div className="form-group-multiple">
          <IonRadioGroup>
            <IonItem lines="none" color="white">
              <IonRadio
                name="floorfoundationok"
                value={true}
                onIonSelect={this.onInputChange}
                checked={formData.floorfoundationok.value}
              />
              <IonLabel>Ja</IonLabel>
            </IonItem>
            <IonItem lines="none" color="white">
              <IonRadio
                name="floorfoundationok"
                value={false}
                onIonSelect={this.onInputChange}
                checked={formData.floorfoundationok.value === false}
              />
              <IonLabel>Nej</IonLabel>
            </IonItem>
          </IonRadioGroup>
        </div>
        <p className="form-description font-size-xs">
          Om <strong>"nej"</strong> förtydliga nedan under{" "}
          <strong>övriga upplysningar</strong>.
        </p>

        <p>
          Underlagen uppfyller branschreglernas krav (Vägg)
          <span style={{ color: "red", fontSize: "24px" }}>*</span>
        </p>
        <div className="form-group-multiple">
          <IonRadioGroup>
            <IonItem lines="none" color="white">
              <IonRadio
                name="wallfoundationok"
                value={true}
                onClick={this.onInputChange}
                checked={formData.wallfoundationok.value}
              />
              <IonLabel>Ja</IonLabel>
            </IonItem>
            <IonItem lines="none" color="white">
              <IonRadio
                name="wallfoundationok"
                value={false}
                onClick={this.onInputChange}
                checked={formData.wallfoundationok.value === false}
              />
              <IonLabel>Nej</IonLabel>
            </IonItem>
          </IonRadioGroup>
        </div>
        <p className="form-description font-size-xs">
          Om <strong>"nej"</strong> förtydliga nedan under{" "}
          <strong>övriga upplysningar</strong>.
        </p>

        <p>
          Finns andra rörgenomföringar genom golvets tätskikt än avlopp
          <span style={{ color: "red", fontSize: "24px" }}>*</span>
        </p>
        <div className="form-group-multiple">
          <IonRadioGroup>
            <IonItem lines="none" color="white">
              <IonRadio
                name="otherleadthrough"
                value={true}
                onIonSelect={this.onInputChange}
                checked={formData.otherleadthrough.value}
              />
              <IonLabel>Ja</IonLabel>
            </IonItem>
            <IonItem lines="none" color="white">
              <IonRadio
                name="otherleadthrough"
                value={false}
                onIonSelect={this.onInputChange}
                checked={formData.otherleadthrough.value === false}
              />
              <IonLabel>Nej</IonLabel>
            </IonItem>
          </IonRadioGroup>
        </div>
        <p className="form-description font-size-xs">
          Om <strong>"ja"</strong> förtydliga nedan under{" "}
          <strong>övriga upplysningar</strong>.
        </p>

        <p>
          Övriga upplysningar/eventuella avvikelser från Byggkeramikrådets
          Branschregler för Våtrum (BBV)
          {formData.otherinformation.required &&
          formData.otherinformation.value === "" ? (
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          ) : (
            ""
          )}
        </p>
        {formData.otherinformation.required &&
        formData.otherinformation.value === "" ? (
          <IonText color="danger">
            <p>Obligatoriskt att fylla i "Övriga upplysningar"</p>
          </IonText>
        ) : (
          ""
        )}
        <div className="form-group">
          <IonTextarea
            name="otherinformation"
            placeholder="Skriv övriga upplysningar/avvikelser"
            value={formData.otherinformation.value}
            onIonChange={this.onInputChange}
            autocapitalize="on"
            maxlength={360}
          />
        </div>

        <div className="form-group form-group-simple form-group-cb">
          <IonItem lines="none" className="ion-no-padding">
            <IonCheckbox
              name="floorslope"
              onIonChange={this.onInputChange}
              checked={formData.floorslope.value}
            />
            <IonLabel>
              Lutning på golv mot golvavlopp uppfyller branschreglernas krav
              (1:50–1:150/1:100–1:200) innan tätskikt applicerats.
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className="ion-no-padding">
            <IonCheckbox
              name="welltypeapproved"
              onIonChange={this.onInputChange}
              checked={formData.welltypeapproved.value}
            />
            <IonLabel>
              Har ny typgodkänd golvbrunn installerats eller är befintlig
              golvbrunn typgodkänd och intakt?
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className="ion-no-padding">
            <IonCheckbox
              name="wellbyothercontractor"
              onIonChange={this.onInputChange}
              checked={formData.wellbyothercontractor.value}
            />
            <IonLabel>Ny golvbrunn monterad av annan entreprenör</IonLabel>
          </IonItem>

          <IonItem lines="none" className="ion-no-padding">
            <IonCheckbox
              name="wellfixedmount"
              onIonChange={this.onInputChange}
              checked={formData.wellfixedmount.value}
            />
            <IonLabel>
              Golvbrunnen är fast monterad, korrekt placerad och i nivå med
              tätskiktet
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className="ion-no-padding">
            <IonCheckbox
              name="sealingworkok"
              onIonChange={this.onInputChange}
              checked={formData.sealingworkok.value}
            />
            <IonLabel>
              Tätskiktsarbetet är utfört enligt aktuell monteringsanvisning
            </IonLabel>
          </IonItem>
        </div>

        <p>Ladda upp bilder</p>
        <div className="form-group form-group-simple form-group-images d-flex">
          {(formData.picture.value || []).map((picture: any, index: number) => (
            <div className="image-col" key={index}>
              <IonItem lines="none" color="white">
                <img src={picture} alt="" />
              </IonItem>
              <span
                className="icon-remove"
                onClick={() => this.props.removePicture(index)}
              ></span>
            </div>
          ))}

          {fileInput}

          <div className="image-col">
            <IonItem lines="none" color="white" onClick={this.openFileInput}>
              <IonIcon icon={add} className="icon-add" color="primary" />
            </IonItem>
          </div>
        </div>
      </IonItemGroup>
    );
  }
}

export default QualityDocumentSectionThree;
